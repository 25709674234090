<template>
  <div class="product-components-ewindow-six-wrapper">
    <div class="ewindow-six-swiper-wrapper">
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        @slideChangeTransitionStart="slideChangeTransitionEnd"
        @ready="swiperLoad"
      >
        <template
          v-for="(item, index) in ewindowSixList"
        >
          <swiper-slide
            :key="index"
            class="ewindow-six-slide"
          > 
            <div
               class="ewindow-six-slide-img"
            >
              <img
               
                :src="item.imgage"
              />
            </div>
            <div
              class="ewindow-six-slide-des"
            >
              <div class="title">
                {{ $t(item.prop).title[index] }}
              </div>
              <div class="des">
                {{ $t(item.prop).des[index] }}
              </div>
            </div>
          </swiper-slide>
        </template>
      </swiper>
      <div class="list-tabs">
        <div class="list-tabs-list">
          <div 
            v-for="(item, index) in $t('ewindowSix.title')"
            :key="index"
            :class="index === swiperActive ? 'active' : ''"
            @click="swiperToActive(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="ewindow-navigation">
          <div class="ewindow-swiper-button-prev">
            <img src="@/assets/image/swiper-pagination.png" />
          </div>
          <div class="ewindow-swiper-button-next">
            <img src="@/assets/image/swiper-pagination.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="ewindow-six-section">
      <div class="ewindow-six-section-img">
        <img src="@/assets/image/product/s3.png" />
      </div>
      <div class="ewindow-six-section-text">
        <div class="title">
          {{ $t('ewindowSix.section.title')[0] }}
        </div>
        <div class="des">
          {{ $t('ewindowSix.section.des')[0] }}
        </div>
      </div>
    </div>
    <div class="ewindow-six-section">
      <div class="ewindow-six-section-text">
        <div class="title">
          {{ $t('ewindowSix.section.title')[1] }}
        </div>
        <div class="des">
          {{ $t('ewindowSix.section.des')[1] }}
        </div>
      </div>
      <div class="ewindow-six-section-img">
        <img src="@/assets/image/product/s4.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { configProduct } from '@/utils/config'
export default {
  name: 'ProductComponentsEwindowSix',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      ewindowSixList: configProduct.EwindowSixSwiper,
      swiperActive: 0,
      swiper: null,
      swiperOptions: {
        navigation: {
          nextEl: '.ewindow-swiper-button-next',
          prevEl: '.ewindow-swiper-button-prev',
        }
      }
    }
  },
  methods: {
    swiperLoad() {
      this.swiper = this.$refs.mySwiper.$swiper
      this.swiperActive = this.getActiveIndex()
    },
    getActiveIndex() {
      return this.swiper.realIndex
    },
    slideChangeTransitionEnd() {
      this.swiperActive = this.getActiveIndex()
    },
    swiperToActive(index) {
      this.swiper.slideToLoop(index, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-components-ewindow-six-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .ewindow-six-swiper-wrapper {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 100px;
    .ewindow-six-slide {
      width: 100%;
      display: flex;
      height: 530px;
      justify-content: space-between;
      .ewindow-six-slide-img {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ewindow-six-slide-des {
        width: 43%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 80px;
        .title {
          color: rgba(34, 34, 34, 1);
          font-size: 32px;
          font-weight: bold;
          line-height: 54px;
        }
        .des {
          font-size: 18px;
          color: rgba(51, 51, 51, 1);
          line-height: 32px;
          margin-top: 20px;
        }
      }
    }
    .list-tabs {
      display: flex;
      width: 60%;
      height: 64px;
      justify-content: space-between;
      &>div {
        display: flex;
        height: 100%;
        align-items: center;
        &:nth-child(1) {
          height: 100%;
          &>div {
            border-bottom: 2px solid #fff;
            margin-right: 48px;
            color: rgba(136, 136, 136, 1);
            font-size: 16px;
            cursor: pointer;
            font-weight: bold;
            transition: all 0.3s;
            height: 100%;
            display: flex;
            align-items: center;
            &:hover, &.active {
              color: rgba(34, 34, 34, 1);
              border-color: rgba(51, 51, 51, 1);
              transition: all 0.3s;
            }
          }
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          &>div {
            cursor: pointer;
            display: flex;
            &:nth-child(2) {
              transform: rotate(180deg);
              margin-left: 25px;
            }
          }
        }
      }
    }
  }
  .ewindow-six-section {
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 450px;
    &:nth-child(3) {
      margin-bottom: 170px;
    }
    .ewindow-six-section-img {
      width: 56%;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .ewindow-six-section-text {
      display: flex;
      width: 35%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      .title {
        color: rgba(34, 34, 34, 1);
        font-size: 32px;
        font-weight: bold;
        line-height: 54px;
      }
      .des {
        line-height: 32px;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
}
</style>